<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header>兑换详情</Header>
    <div class="content">
      <div class="head">
        <img :src="detail.goods.showPic" alt="" />
      </div>
      <div class="title">{{ detail.goods.name }}</div>
      <div class="line"></div>
      <!-- <div class="key">
        <div class="text">消耗钥匙个数：</div>
        <div class="num">{{ detail.haveQ || 0 }}个</div>
      </div> -->
      <div class="address" v-if="detail.is_real == 1">
        <div class="addrTitle">收货信息</div>
        <div class="name">{{ detail.address }}</div>
        <div class="info">{{ detail.user_name }} {{ detail.tel }}</div>
      </div>
      <div class="line" v-if="detail.is_real == 1"></div>
      <div class="commodityFreight" v-if="detail.composer.fare">
        <div class="left">商品运费</div>
        <div class="right">
          <span>{{ detail.composer.fare }}</span>
          <div @click="show = true" v-if="detail.is_pay == 0" class="btn">
            支付运费
          </div>
        </div>
      </div>
      <div class="line" v-if="detail.composer.fare"></div>
      <div class="science">
        <span>使用的藏品材料：</span>
        <span>{{ detail.pointNumber }}交子</span>
      </div>
      <div class="list">
        <div class="bg" v-for="item in detail.haveGoodList" :key="item.id">
          <div class="item">
            <div class="left">
              <img :src="item.listPic" alt="" />
            </div>
            <div class="middle">
              <div class="title">
                <span v-if="item.goodsNumber.length === 1">{{
                  item.goodsNumber[0]
                }}</span>
                <span v-else>{{ item.name }}</span>
              </div>
            </div>
            <div
              v-if="goodsNumber.length > 1"
              class="right"
              @click="
                goodsNumber = item.goodsNumber;
                showList = true;
              "
            >
              展开全部 <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 展开 -->
    <div class="commodityList" v-if="showList" @click="showList = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="product">
            <div>
              <div class="checkedbox">
                <div
                  style="font-size: 16px; font-weight: 600; margin-right: 10px"
                >
                  数量: {{ goodsNumber.length }}
                </div>
              </div>
            </div>
            <div
              @click="showList = false"
              style="color: #2376ff; font-size: 14px"
            >
              收起
            </div>
          </div>

          <div style="overflow: auto; margin-top: 10px">
            <div class="product_list" v-for="item in goodsNumber" :key="item">
              <div class="product_num">
                <div>{{ item }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="show" round position="bottom">
      <div class="pay">
        <div class="top">
          支付运费
          <i class="close" @click="show = false"></i>
        </div>
        <div class="middle">
          <div class="pic">
            <div class="picItem">¥{{ detail.composer.fare }}</div>
            <div class="info">支付运费后商品才可发货</div>
          </div>
          <div class="payType">
            <div class="payTitle">支付方式</div>
            <div class="listItem">
              <div class="left">
                华夏钱包 <span>（剩余¥{{ anyUserinfo.money }}）</span>
              </div>
              <div class="right">
                <van-checkbox v-model="checked"></van-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" @click="goPay">支付</div>
      </div>
    </van-popup>

    <van-overlay :show="showPwd">
      <div class="wap">
        <div class="wap_affirm">
          <div
            @click="
              showPwd = false;
              value = '';
            "
            class="cha"
          >
            ×
          </div>
          <div>请输入交易密码</div>
          <div class="forgotPwd"></div>
          <!-- <div class="forgotPwd" @click="$router.push('/forgotPwd')">
            忘记密码
          </div> -->
        </div>
        <div class="wap_form">订单金额</div>
        <div class="wap_num">￥{{ detail.composer.fare }}</div>
        <div class="wap_balance">
          余额<span
            >(￥
            {{ anyUserinfo.money }}
            )</span
          >
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :error-info="errorInfo"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @blur="showKeyboard = true"
          @input="onInput"
          @delete="onDelete"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: { goods: {}, haveGoodList: [] },
      showList: false,
      showPwd: false,
      goodsNumber: [],
      initLoading: true,
      showKeyboard: true,
      token: null,
      show: false,
      checked: true,
      value: "",
      errorInfo: "",
      anyUserinfo: {},
      order_no: null,
    };
  },
  mounted() {
    this.initLoading = true;
    this.token = this.$route.query.token;
    this.getUserComposeLogInfo();

    //余额信息
    let params = {
      token: this.token,
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.anyUserinfo = res.data;
      }
    });
  },
  methods: {
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
    // 余额支付
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        this.runPayComposerPostage();
      }
    },
    async getUserComposeLogInfo() {
      try {
        const data = await this.$api.getpointComposeLogInfo({
          id: this.$route.query.id,
          token: this.token,
        });
        if (data.code === 0) {
          this.detail = data.data || { goods: {}, haveGoodList: [] };
          this.order_no = this.detail.goods_order.order_no;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    goPay() {
      if (this.detail.goods_order.order_no) {
        //
        this.showPwd = true;
        this.show = false;
      } else {
        const query = {
          token: this.token,
          id: this.detail.id,
        };
        this.$api.payPostage(query).then((e) => {
          if (e.code === 0) {
            //
            this.order_no = e.data.order_no;
            this.showPwd = true;
            this.show = false;
          } else {
            return this.$toast(e.msg);
          }
        });
      }
    },
    runPayComposerPostage() {
      const query = {
        token: this.token,
        order_no: this.order_no,
        pay_channel: "balance",
        payPassword: this.value,
      };
      this.$api.runPayComposerPostage(query).then((e) => {
        if (e.code === 100000) {
          this.showPwd = false;
          this.getUserComposeLogInfo();
        }
        this.value = "";
        return this.$toast(e.msg);
      });
    },
  },
};
</script>

<style scoped lang="less">
.content {
  .head {
    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #010000;
    padding: 10px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .line {
    height: 10px;
    background: #f8f8f8;
  }
  .key {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: flex-end;
    padding: 10px 20px 5px;
    .num {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
  }
  .commodityFreight {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #010000;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-right: 10px;
      }
      .btn {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 70px;
        height: 28px;
        background: #0754d3;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .address {
    padding: 15px 20px;
    .addrTitle {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #010000;
    }
    .name {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin: 8px 0 5px;
    }
    .info {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
  .science {
    padding: 18px 20px 0;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list {
    padding: 20px;
    .bg {
      background: #f8f8f8;
      padding: 15px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .item {
        display: flex;
        align-items: center;
        .left {
          img {
            width: 70px;
            vertical-align: middle;
          }
        }
        .middle {
          padding-left: 10px;
          flex: 1;
          .title {
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            color: #000000;
          }
          .time {
            font-size: 12px;
            margin-top: 10px;
            color: #a4a5a9;
          }
        }
        .right {
          font-size: 14px;
          color: #2376ff;
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #2376ff;
            border-right: 2px solid #2376ff;
            transform: rotate(45deg);
            margin-left: 3px;
          }
        }
      }
    }
  }
}

.commodityList {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  .wrapper {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
  }
  .block {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 50%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .product {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .checkedbox {
    align-items: center;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .checkbox {
    display: flex;
    padding-right: 10px;
    div.checkBoxChild {
      margin: 0 10px;
      display: flex;
      align-items: center;
      i {
        display: flex;
        flex-direction: column;
        margin: 3px 0;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        &.just {
          &::after {
            display: block;
            content: "";
            border-top: 5px solid transparent;
            border-bottom: 5px solid #ddd;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.just {
              &::after {
                border-bottom: 5px solid #333;
              }
            }
          }
        }
        &.inverted {
          &::after {
            display: block;
            content: "";
            border-top: 5px solid #ddd;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.inverted {
              &::after {
                border-top: 5px solid #333;
              }
            }
          }
        }
      }
    }
  }
  .product_list {
    display: flex;
    font-size: 16px;
    line-height: 40px;
    justify-content: space-between;
    margin-right: 20px;
    .product_text {
      display: flex;
      align-items: center;
    }
    .product_num {
      margin-left: 10px;
      display: flex;
      font-size: 12px;
    }
    .num_several {
      margin-left: 5px;
      font-size: 10px;
      color: #aaa;
      &.active {
        color: #fab878;
        span {
          padding: 3px;
        }
      }
      &.unActive {
        color: #cecece;
        span {
          background: #f8f8f8;
          padding: 3px;
        }
      }
      &.lock {
        color: #009944;
        span {
          background: #deffee;
          padding: 3px;
        }
      }
    }
    .product_img {
      width: 4px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .even {
    font-size: 12px;
    color: #abaab9;
    background-color: #abaab957;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 6px;
      height: 4px;
    }
  }
}
.pay {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  .top {
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #f8f8f8;
    margin-bottom: 15px;
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 15px;
      &::after {
        width: 100%;
        height: 1px;
        background: #999999;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        content: "";
        height: 100%;
        width: 1px;
        background: #999999;
      }
    }
  }
  .bottom {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background: #0754d3;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    margin-bottom: 25px;
    .pic {
      text-align: center;
      margin-bottom: 30px;
      .picItem {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #e76810;
      }
      .info {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .payType {
      .payTitle {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
      }
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .right {
        }
      }
    }
  }
}

.wap {
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  // height: 70%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  z-index: 999;
  .wap_affirm {
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    div {
      flex: 2;
      text-align: center;
      font-size: 16px;
    }
    .cha {
      flex: 1;
      color: #666666;
      text-align: left;
      font-size: 30px;
    }
    .forgotPwd {
      flex: 1;
      text-align: right;
      font-size: 14px;
      color: rgb(rgb(7 84 211));
    }
  }
  .wap_balance {
    font-size: 10px;
    margin-bottom: 30px;
    span {
      color: #999999;
    }
  }
  .wap_form {
    font-size: 10px;
    margin-top: 20px;
  }
  .wap_num {
    height: 19px;
    font-size: 18px;
    font-weight: 400;
    color: #e76810;
    line-height: 20px;
    margin-top: 10px;
  }
}
/deep/.van-password-input__security li {
  border: 2px #666666 solid;
  border-radius: 5px;
}
/deep/.van-password-input {
  position: relative;
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative !important;
}
</style>
